import React, {Component} from "react";
import Box from "@mui/material/Box";
import {
    AppBar, Backdrop, CircularProgress, IconButton,
    ListItem, ListItemText, Toolbar
} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import plyr from 'plyr'
import 'plyr/dist/plyr.css'
import $ from "jquery";
let loadingStateInterval;
export default class Player extends Component {

    componentDidMount() {
        if (window.main.android()) {
            window.Android.setNavAndStatus("#000000");
        } else {
            if (navigator.userAgent.includes("OtakuTime")) {
                let item = JSON.parse("{}");
                item.type = "changeBottom";
                item.color = "#000000";
                item.isDark = "true";
                window.webkit.messageHandlers.m.postMessage(item);
                let item2 = JSON.parse("{}");
                item2.type = "changeTop";
                item2.color = "#000000";
                item2.isDark = "true";
                window.webkit.messageHandlers.m.postMessage(item2);
            }
        }
        if (window.main.isOtakuTime()) {
            this.player = new plyr('.js-plyr', {"controls":['play-large', 'play', 'progress', 'current-time', 'mute', 'settings'],fullscreen: {enabled: false}});
        } else {
            this.player = new plyr('.js-plyr', {"controls":['play-large', 'play', 'progress', 'current-time', 'mute', 'settings', 'fullscreen']});
        }
        this.player.autoplay = true;
        this.player.source = {
            type: 'video',
            title: window.localStorage.getItem("videoName"),
            sources: [
                {
                    src: window.localStorage.getItem("sdUrl"),
                    type: 'video/mp4',
                    size: 360,
                },
                {
                    src: window.localStorage.getItem("hdUrl"),
                    type: 'video/mp4',
                    size: 720,
                },
            ],
        };

        loadingStateInterval = setInterval(() => {
            let loadingState = $(".plyr--loading");
            if (loadingState.length !== 0) {
                window.playerApp.setState({
                    ldn: true
                });
            } else {
                window.playerApp.setState({
                    ldn: false
                });
            }
        },100);
    }

    componentWillUnmount() {
        clearInterval(loadingStateInterval);
        this.player.destroy();
        if (window.main.android()) {
            if (window.main.checkTheme()) {
                window.Android.setNav("#262e39");
                window.Android.setStatus("#182128");
            } else {
                window.Android.setNav("#ffffff");
                window.Android.setStatus("#efeeee");
            }
        }
        window.playerApp.setState({
            ldn: false
        });
        if (!window.main.checkLoggedAndPremium()) {
            if (window.main.android()) {
                window.main.openFull();
            }
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingPlayer: true,
            ldn: false
        };
        window.playerApp = this;
    }

    checkTheme = () => {
        if (localStorage.getItem("theme") !== null) {
            return localStorage.getItem("theme").includes("true");
        } else {
            return true
        }
    }

    render() {
        return <Box style={{background: "#000000",height: "100vh"}}>
            <Box style={{background: "#000000"}}>
                <AppBar position="static" elevation={0} style={{background: "#000000"}}>
                    <Toolbar variant="dense" sx={{paddingLeft: "9px",paddingRight:"9px"}}>
                        <IconButton
                            onClick={() => {
                                // eslint-disable-next-line no-restricted-globals
                                history.back();
                            }}
                            size="medium"
                            edge="start"
                            color="inherit"
                            aria-label="back"
                            sx={{ marginRight: "0px" }}
                        >
                            <ArrowBack />
                        </IconButton>
                        <ListItem sx={{padding: "0px"}}>
                            <ListItemText primary={window.localStorage.getItem("videoName")} secondary={""} />
                        </ListItem>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box style={{background: "#000000", flexGrow: 1,alignItems: "center",textAlign: "center",height: "92%"}}>
                <video autoPlay={true} className='js-plyr plyr' style={{height: "100%",width: "100%"}} controls/>
            </Box>
            <Backdrop
                sx={{ position: "fixed",height: "100%",color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.ldn}
            >
                <CircularProgress sx={{color: "#2196f3"}} />
            </Backdrop>
        </Box>
    }
}
