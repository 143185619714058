import './App.css';
import React, {Component} from "react";
import {
    AppBar,
    Backdrop,
    Box,
    CardActionArea,
    CardMedia,
    CircularProgress,
    createTheme,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Fade,
    Grid,
    IconButton,
    InputBase,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    ThemeProvider,
    Toolbar,
    Typography
} from "@mui/material";
import {ColorModeContext, getDesignTokens} from "./functions";
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import HomePage from "./pages/home";
import InfoPage from "./pages/info";
import Account from "./pages/account";
import Update from "./pages/update";
import {ArrowBack, SearchOutlined, VerifiedOutlined} from "@mui/icons-material";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import $ from "jquery";
import Player from "./pages/player";
import List from "@mui/material/List";

let theme = createTheme(getDesignTokens("dark"));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

// eslint-disable-next-line no-unused-vars
let isAndroid = false;
// eslint-disable-next-line no-unused-vars
let isIos = false;

let five;
window.five = five;
class App extends Component {
    componentDidMount() {
        if (navigator.userAgent.includes("SevenZero")) {
            isIos = true;
            window.localStorage.setItem("deviceId","IPHONE");
        } else {
            if (navigator.userAgent.includes("IBRAHIMSEVEN")) {
                isAndroid = true;
                const deviceId = window.Android.codeWith("Y29tLnNhbmltZW5ldy5hcGsuTWFpbkFjdGl2aXR5LnJldHVybkRhdGEgPSBhbmRyb2lkLnByb3ZpZGVyLlNldHRpbmdzLlNlY3VyZS5nZXRTdHJpbmcobWFpbkFjdGl2aXR5LmdldENvbnRlbnRSZXNvbHZlcigpLCBhbmRyb2lkLnByb3ZpZGVyLlNldHRpbmdzLlNlY3VyZS5BTkRST0lEX0lEKTs=");
                setTimeout(() => {
                    if (deviceId.length === 0) {
                        setTimeout(() => {
                            const deviceId = window.Android.codeWith("Y29tLnNhbmltZW5ldy5hcGsuTWFpbkFjdGl2aXR5LnJldHVybkRhdGEgPSBhbmRyb2lkLnByb3ZpZGVyLlNldHRpbmdzLlNlY3VyZS5nZXRTdHJpbmcobWFpbkFjdGl2aXR5LmdldENvbnRlbnRSZXNvbHZlcigpLCBhbmRyb2lkLnByb3ZpZGVyLlNldHRpbmdzLlNlY3VyZS5BTkRST0lEX0lEKTs=");
                            window.localStorage.setItem("deviceId",deviceId);
                        },5000);
                    } else {
                        window.localStorage.setItem("deviceId",deviceId);
                    }
                },5000);
            } else {
                if (this.isOtakuTime()) {
                    window.localStorage.setItem("deviceId",this.otakuDeviceId());
                    if (this.isOtakuAndUser()) {
                        if (!this.checkLogged()) {
                            let params = new URLSearchParams();
                            params.append("email",this.otakuEmail());
                            params.append("password",this.otakuPassword());
                            fetch('https://app.sanime.net/function/h10.php?page=login', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                },
                                body: params
                            }).then(response => response.json())
                                .then((json) => {
                                    if (json.code === "1") {
                                        this.setState({
                                            loading: false,
                                        });
                                        localStorage.setItem("logged",true);
                                        localStorage.setItem("loggedData",JSON.stringify(json.message));
                                    }
                                }).catch((err) => {}
                            );
                        }
                    }
                } else {
                    window.localStorage.setItem("deviceId","web id");
                    this.deny();
                }
            }
        }

        if (localStorage.getItem("theme") !== null) {
            if (localStorage.getItem("theme").includes("true")) {
                theme = createTheme(getDesignTokens("dark"));
                this.setState({
                    mode: "dark"
                });
                let item = JSON.parse("{}");
                item["R"] = 24;
                item["G"] = 33;
                item["B"] = 40;
                item["dark"] = 1;
                item["type"] = "theme";
                let ep = btoa(unescape(encodeURIComponent(JSON.stringify(item))));
                if (isIos) {
                    window.open("https://snoanime.com/ios.php?id=" + ep,"_self");
                } else {
                    if (isAndroid) {
                        window.Android.setNavAndStatus("#182128");
                    } else {
                        if (this.isOtakuTime()) {
                            let item = JSON.parse("{}");
                            item.type = "changeTheme";
                            item.topColor = "#182128";
                            item.bottomColor = "#182128";
                            item.backgroundColor = "#182128";
                            item.isDark = "true";
                            window.webkit.messageHandlers.m.postMessage(item)
                        }
                    }
                }
            } else {
                theme = createTheme(getDesignTokens("light"));
                this.setState({
                    mode: "light"
                });
                let item = JSON.parse("{}");
                item["R"] = 239;
                item["G"] = 238;
                item["B"] = 238;
                item["dark"] = 0;
                item["type"] = "theme";
                let ep = btoa(unescape(encodeURIComponent(JSON.stringify(item))));
                if (isIos) {
                    window.open("https://snoanime.com/ios.php?id=" + ep,"_self");
                } else {
                    if (isAndroid) {
                        window.Android.setLight();
                        window.Android.setNavAndStatus("#efeeee");
                    } else {
                        if (this.isOtakuTime()) {
                            let item = JSON.parse("{}");
                            item.type = "changeTheme";
                            item.topColor = "#efeeee";
                            item.bottomColor = "#efeeee";
                            item.backgroundColor = "#efeeee";
                            item.isDark = "false";
                            window.webkit.messageHandlers.m.postMessage(item)
                        }
                    }
                }
            }
        }
        setTimeout(() => {
            window.main.bannedList();
        },10000);
        this.reLogin();
    }
    constructor() {
        super();
        this.state = {
            mode: "dark",
            loading: false,
            randomAnime: "/",
            searchDialog: false,
            searchedData: [],
            searched: false,
            searchedTitle: '',
            messageDialog: false,
            messageDialogText: "",
            messageDialogTitle: "",
            messageBtn: "",
            messageCnl: true,
            messageLink: "",
            player: false,
            fullAds: false,
            five:6,
            pin: {
                open: false,
                message: "",
                title: "",
                link: "",
            }
        }
        localStorage.setItem("latestAnime","");
        window.main = this;
    }
    closeFull = () => {
        this.setState({
            fullAds: false,
            five: 6
        });
        clearInterval(window.five);
    }
    openFull = () => {
        if (!this.checkLoggedAndPremium()) {
            this.openLink("https://sanime.net/ads.php");
        }
    }

    isOtakuTime = () => {
        return navigator.userAgent.includes("OtakuTime");
    }
    deny = () => {
        setTimeout(() => {
            let div = document.createElement("div");
            div.innerText = "غير مسموح";
            div.className = "centerText";
            if (window.main.checkTheme()) {
                div.style.color = "#ffffff";
            } else {
                div.style.color = "#000000";
            }
            document.body.innerHTML = "";
            document.body.append(div);
            document.body.style.placeItems = "center";
            document.body.style.justifyContent = "center";
            document.body.style.color = "white";
            document.body.style.fontFamily = "'Changa'";
        },100);
        setTimeout(() => {
            //window.close();
        },5000);
    }
    android = () => {
        return isAndroid;
    }
    ios = () => {
        return isIos;
    }
    showMessage = (text,title = "",btn = "حسنا",link = "",cnl = true) => {
        this.setState({
            messageDialog: true,
            messageDialogText: text,
            messageDialogTitle: title,
            messageBtn: btn,
            messageCnl: cnl,
            messageLink: link
        });
    }
    closeMessage = () => {
        this.setState({
            messageDialog: false
        });
    }
    goOk = () => {
        if (this.state.messageLink.length > 5) {
            this.setState({
                messageDialog: false
            });
            let item = JSON.parse("{}");
            item["sd"] = this.state.messageLink;
            item["type"] = "link";
            let ep = btoa(unescape(encodeURIComponent(JSON.stringify(item))));
            if (navigator.userAgent.includes("SevenZero")) {
                window.open("https://snoanime.com/ios.php?id=" + ep,"_self");
            }
        } else {
            this.setState({
                messageDialog: false
            });
        }
    }
    switchTheme = () => {
        if (theme.palette.mode.includes("dark")) {
            theme = createTheme(getDesignTokens("light"));
            this.setState({
                mode: "light"
            });
            localStorage.setItem("theme",false);
            let item = JSON.parse("{}");
            item["R"] = 239;
            item["G"] = 238;
            item["B"] = 238;
            item["dark"] = 0;
            item["type"] = "theme";
            let ep = btoa(unescape(encodeURIComponent(JSON.stringify(item))));
            if (isIos) {
                window.open("https://snoanime.com/ios.php?id=" + ep,"_self");
            } else {
                if (isAndroid) {
                    window.Android.setLight();
                    window.Android.setNavAndStatus("#efeeee");
                } else {
                    if (this.isOtakuTime()) {
                        let item = JSON.parse("{}");
                        item.type = "changeTheme";
                        item.topColor = "#efeeee";
                        item.bottomColor = "#efeeee";
                        item.backgroundColor = "#efeeee";
                        item.isDark = "false";
                        window.webkit.messageHandlers.m.postMessage(item)
                    }
                }
            }
        } else {
            theme = createTheme(getDesignTokens("dark"));
            this.setState({
                mode: "dark"
            });
            localStorage.setItem("theme",true);
            let item = JSON.parse("{}");
            item["R"] = 24;
            item["G"] = 33;
            item["B"] = 40;
            item["dark"] = 1;
            item["type"] = "theme";
            let ep = btoa(unescape(encodeURIComponent(JSON.stringify(item))));
            if (isIos) {
                window.open("https://snoanime.com/ios.php?id=" + ep,"_self");
            } else {
                if (isAndroid) {
                    window.Android.setDark();
                    window.Android.setNavAndStatus("#182128");
                } else {
                    if (this.isOtakuTime()) {
                        let item = JSON.parse("{}");
                        item.type = "changeTheme";
                        item.topColor = "#182128";
                        item.bottomColor = "#182128";
                        item.backgroundColor = "#182128";
                        item.isDark = "true";
                        window.webkit.messageHandlers.m.postMessage(item)
                    }
                }
            }
        }
    }
    switchLoading = () => {
        this.setState({
            loading:!this.state.loading
        });
    }
    openLink = (url) => {
        if (isAndroid) {
            let code = btoa('android.content.Intent i = new android.content.Intent(android.content.Intent.ACTION_VIEW);i.setData(android.net.Uri.parse("'+url+'"));activity.startActivity(i);');
            window.Android.code(code);
        } else {
            if (isIos) {
                let item = JSON.parse("{}");
                item["url"] = url;
                item["type"] = "safari";
                console.log(JSON.stringify(item));
                let ep = btoa(unescape(encodeURIComponent(JSON.stringify(item))));
                window.open("https://snoanime.com/ios.php?id=" + ep,"_self");
            } else {
                if (window.main.isOtakuTime()) {
                    let item = JSON.parse("{}");
                    item.type = "openLink";
                    item.link = url;
                    window.webkit.messageHandlers.m.postMessage(item)
                }
            }
        }
    }
    goLinkWithSearch = (link) => {
        this.setState({
            randomAnime: link,
            searchDialog: false,
        });
        this.closeSearched();
        setTimeout(() => {
            document.getElementById('goRandom').click();
        },100);
    }
    goLink = (link) => {
        this.setState({
            randomAnime: link
        });
        setTimeout(() => {
            document.getElementById('goRandom').click();
            if (link === "/") {
                if (window.main.android()) {
                    if (window.main.checkTheme()) {
                        window.Android.setNavAndStatus("#182128");
                    } else {
                        window.Android.setNavAndStatus("#efeeee");
                    }
                }
            }
        },100);
    }
    accountClear = () => {
        localStorage.removeItem("logged");
        localStorage.removeItem("loggedData");
    }
    checkLogged = () => {
        return localStorage.getItem("logged") !== null;
    }
    checkLoggedAndPremium = () => {
        if (localStorage.getItem("logged") !== null) {
            let userData = localStorage.getItem("loggedData");
            userData = JSON.parse(userData);
            return userData["premium"].toString() === "1";
        } else {
            return false;
        }
    }
    reLogin = () => {
        if (localStorage.getItem("logged") !== null) {
            let userData = localStorage.getItem("loggedData");
            userData = JSON.parse(userData);
            let params = new URLSearchParams();
            params.append("email",userData["email"]);
            params.append("password",userData["password"]);

            if (this.isOtakuAndUser()) {
                let item = JSON.parse("{}");
                item.type = "login";
                item.email = this.otakuEmail();
                item.password = this.otakuPassword();
                window.webkit.messageHandlers.m.postMessage(item);
                let params2 = new URLSearchParams();
                params2.append("email",this.otakuEmail());
                params2.append("password",this.otakuPassword());
                params = params2;
            }

            fetch('https://app.sanime.net/function/h10.php?page=login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: params
            }).then(response => response.json())
            .then((json) => {
                    if (json.code === "1") {
                        this.setState({
                            loading: false,
                        });
                        localStorage.setItem("logged",true);
                        localStorage.setItem("loggedData",JSON.stringify(json.message));
                    } else {
                        // eslint-disable-next-line no-undef
                        if (!this.isOtakuAndUser()) {
                            window.main.showMessage(json.message,"تنبيه","حسنا","",true);
                        }
                        window.main.accountClear();
                    }
            }).catch((err) => {});
        } else {
            return false;
        }
    }

    reLogin2 = (email,password) => {
        let params = new URLSearchParams();
        params.append("email",email);
        params.append("password",password);
        fetch('https://app.sanime.net/function/h10.php?page=login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: params
        }).then(response => response.json())
            .then((json) => {
                if (json.code === "1") {
                    this.setState({
                        loading: false,
                    });
                    localStorage.setItem("logged",true);
                    localStorage.setItem("loggedData",JSON.stringify(json.message));
                }
            }).catch((err) => {}
        );
    }

    isOtakuAndUser = () => {
        if (this.isOtakuTime()) {
            if (navigator.userAgent.includes("UserEmail")) {
                const index = navigator.userAgent.indexOf("UserPassword/");
                if (index !== -1) {
                    return true;
                }
            }
        }
        return false
    }

    otakuEmail = () => {
        if (this.isOtakuTime()) {
            if (navigator.userAgent.includes("UserEmail")) {
                const index = navigator.userAgent.indexOf("UserPassword/");
                const index2 = navigator.userAgent.indexOf("UserEmail/");
                if (index !== -1) {
                    let password = navigator.userAgent.substring(index + "UserPassword/".length);
                    let email = navigator.userAgent.substring(index2 + "UserEmail/".length);
                    email = email.replace("UserPassword/"+password,"").trim();
                    return email;
                }
            }
        }
        return "";
    }

    otakuLogin = (email,password) => {
        if (this.isOtakuTime()) {
            let item = JSON.parse("{}");
            item.type = "login";
            item.email = email;
            item.password = password;
            window.webkit.messageHandlers.m.postMessage(item);
        }
        return "";
    }

    otakuPassword = () => {
        if (this.isOtakuTime()) {
            if (navigator.userAgent.includes("UserEmail")) {
                const index = navigator.userAgent.indexOf("UserPassword/");
                if (index !== -1) {
                    return navigator.userAgent.substring(index + "UserPassword/".length);
                }
            }
        }
        return "";
    }

    otakuDeviceId = () => {
        if (this.isOtakuTime()) {
            if (navigator.userAgent.includes("UUID")) {
                const index = navigator.userAgent.indexOf("UUID/");
                const indexColor = navigator.userAgent.indexOf("Color/");
                if (index !== -1 && indexColor !== -1) {
                    return navigator.userAgent.substring(index + "UUID/".length, indexColor);
                }
            }
        }
        return "";
    }

    otakuRestart = () => {
        if (this.isOtakuTime()) {
            let item = JSON.parse("{}");
            item.type = "restartApp";
            window.webkit.messageHandlers.m.postMessage(item);
        }
    }

    accountData = () => {
        if (this.checkLogged()) {
         return JSON.parse(localStorage.getItem("loggedData"));
        }
    }
    closeSearch = () => {
        this.setState({
            searchDialog: false
        });
    }
    closeSearched = () => {
        this.setState({
            searchedData: [],
            searched: false,
            searchDialog: false,
            searchedTitle: ''
        });
    }
    checkTheme = () => {
        if (localStorage.getItem("theme") !== null) {
            return localStorage.getItem("theme").includes("true");
        } else {
            return true
        }
    }
    goSearch = () => {
        this.setState({
            loading: true,
            searchDialog: false
        });
        let url = 'https://app.sanime.net/function/h10.php?page=search&name='+this.state.searchedTitle;
        if (this.checkLogged()) {
            url = 'https://app.sanime.net/function/h10.php?page=search&name='+this.state.searchedTitle+"&email="+this.accountData().email;
        }
        fetch(url).then((value) => {
            value.text().then((value) => {
                let JS = JSON.parse(value);
                this.setState({
                    searchedData: JS,
                    searched: true,
                    loading: false,
                });
            });
        }).catch((error) => {
            this.setState({
                loading: false,
            });
        });
    }
    bannedList = () => {
        const deviceId = window.localStorage.getItem("deviceId");
        fetch('https://app.sanime.net/banned.txt').then((response) => {
            response.text().then((va) => {
                if (va.includes(deviceId)) {
                    if (isAndroid) {
                        window.main.showMessage("أنت محظور من أستخدام التطبيق","","حسنا","",false)
                        setTimeout(() => {
                            window.Android.code("bWFpbkFjdGl2aXR5LmZpbmlzaCgpOw==");
                        },3000);
                    }
                }
            });
        })
    }
    onBackPress = () => {
        if (window.location.pathname === "/") {
            if (window.main.android()) {
                if (window.main.checkTheme()) {
                    window.Android.setNavAndStatus("#182128");
                } else {
                    window.Android.setNavAndStatus("#efeeee");
                }
            }
        }
    }
    if360 = () => {
        return window.innerWidth <= 390;
    }
    closePin = () => {
        this.setState({
            pin: {
                open: false,
                message: this.state.pin.message,
                title: this.state.pin.title,
                link: this.state.pin.link,
            }
        })
    }

    render() {
        return (
            <BrowserRouter>
                <ColorModeContext.Provider value={this.state.mode}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Backdrop
                            sx={{ position: "fixed",height: "100%",color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={this.state.loading}
                        >
                            <CircularProgress sx={{color: "#2196f3"}} />
                        </Backdrop>
                        <main style={{height: "100%",overflow: "scroll"}} className={"fullScreenDiv"}>
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/info" element={<InfoPage />} />
                                <Route path="/account" element={<Account />} />
                                <Route path="/update" element={<Update />} />
                                <Route path="/player" element={<Player />} />
                            </Routes>
                            <Dialog
                                fullWidth
                                open={this.state.searchDialog}
                                onClose={this.closeSearch}
                                TransitionComponent={Transition}
                                PaperProps={{
                                    sx: {
                                        margin: "0px",
                                        top: "auto",
                                        right: "auto",
                                        bottom: "20px",
                                        left: "auto",
                                        position: "fixed",
                                        background: "transparent",
                                        boxShadow: "unset"
                                    }
                                }}
                            >
                                <Box>
                                    <Paper
                                        component="form"
                                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center',borderRadius: "100px" }}
                                    >
                                        <InputBase
                                            id={"searchId"}
                                            sx={{ ml: 1, flex: 1,borderRadius: "100px" }}
                                            placeholder="أسم الأنمي"
                                            onChange={(value) => {
                                                this.setState({
                                                    searchedTitle: value.target.value
                                                });
                                            }}
                                            onClick={() => {
                                                setTimeout(() => {
                                                    $("html").animate({ scrollTop: -10000 }, 500);
                                                    $("body").animate({ scrollTop: -10000 }, 500);
                                                },200);
                                            }}
                                            inputProps={{ 'aria-label': 'type' }}
                                        />
                                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                        <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={this.goSearch}>
                                            <SearchOutlined />
                                        </IconButton>
                                    </Paper>
                                    <Box sx={{height: "10px"}}/>
                                </Box>
                            </Dialog>
                            <Dialog
                                fullScreen
                                onClose={this.closeSearched}
                                open={this.state.searched}
                                TransitionComponent={Transition}
                            >
                                <Box>
                                    <Box style={{background: this.checkTheme() ? "#182128" : "#efeeee"}}>
                                        <AppBar position="static" elevation={0} color={"background"} enableColorOnDark>
                                            <Toolbar variant="dense" sx={{paddingLeft: "9px",paddingRight:"9px"}}>
                                                <IconButton
                                                    onClick={this.closeSearched}
                                                    size="medium"
                                                    edge="start"
                                                    color="inherit"
                                                    aria-label="back"
                                                    sx={{ marginRight: "0px" }}
                                                >
                                                    <ArrowBack />
                                                </IconButton>
                                                <ListItem sx={{padding: "0px"}}>
                                                    <ListItemText primary={this.state.searchedTitle} secondary={""} />
                                                </ListItem>
                                            </Toolbar>
                                        </AppBar>
                                    </Box>
                                    <Box style={{marginTop: '7px',background: this.checkTheme() ? "#182128" : "#efeeee", flexGrow: 1}} height={'95vh'}>
                                        <Grid sx={{ flexGrow: 1,background: "#182128" }} container spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container justifyContent="center" spacing={1.4} style={{marginBottom: '7px'}}>
                                                    {this.state.searchedData.map(r =>
                                                        <Grid key={r.id} item>
                                                            <Card sx={{ width: window.main.if360() ? 105 : 120 }} >
                                                                <CardActionArea onClick={this.goLinkWithSearch.bind("this",'/info?id='+r.id)}>
                                                                    <CardMedia
                                                                        component="img"
                                                                        height="185"
                                                                        image={r.image}
                                                                        alt={r.name}
                                                                    />
                                                                    <Divider sx={{height: 1.2}} color={'#00b2ff'}/>
                                                                    <Stack style={{paddingRight: '4px',paddingLeft: '4px',paddingBottom: '3px',paddingTop: '1px'}} spacing={0}>
                                                                        <Typography style={{textOverflow: "ellipsis",overflow: "hidden",whiteSpace: "nowrap",fontSize: '14px'}}>{r.name}</Typography>
                                                                        <Typography style={{textAlign: 'right',fontSize: '14px'}}>{r.status}</Typography>
                                                                    </Stack>
                                                                </CardActionArea>
                                                            </Card>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Dialog>
                            <Dialog
                                open={this.state.messageDialog}
                                onClose={this.closeMessage}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                {
                                    this.state.messageDialogTitle.length > 1 ? <DialogTitle id="alert-dialog-title">
                                        {this.state.messageDialogTitle}
                                    </DialogTitle> : <div/>
                                }
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {this.state.messageDialogText}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    {
                                        this.state.messageCnl ? <Button onClick={this.closeMessage}>خروج</Button> : <div/>
                                    }
                                    <Button onClick={this.goOk} autoFocus>
                                        {this.state.messageBtn}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                fullScreen
                                onClose={this.closeFull}
                                open={this.state.fullAds}
                                TransitionComponent={Transition}
                            >
                                <Box>
                                    <Box style={{background: this.checkTheme() ? "#182128" : "#efeeee"}}>
                                        <AppBar position="static" elevation={0} color={"background"} enableColorOnDark>
                                            <Toolbar variant="dense" sx={{paddingLeft: "9px",paddingRight:"9px"}}>
                                                <ListItem sx={{padding: "0px"}}>
                                                    <ListItemText primary={"انتظر "+this.state.five+" ثواني"} secondary={""} />
                                                </ListItem>
                                            </Toolbar>
                                        </AppBar>
                                    </Box>
                                    <Box style={{marginTop: '7px',background: this.checkTheme() ? "#182128" : "#efeeee", flexGrow: 1}} height={'95vh'}>
                                        <ListItem sx={{padding: "1px 5px 1px 5px"}}>
                                            <ListItemText className={"centerText30"} primary="سوف يظهر أعلان عند مشاهدتة قم بلرجوع للتطبيق" />
                                        </ListItem>
                                        <List>
                                            <ListItem sx={{padding: "1px 5px 1px 5px"}}>
                                                <ListItemText className={"centerText30"} primary="يمكنكم أزالة الأعلانات من خيارات الحساب أيضا" />
                                            </ListItem>
                                            <ListItem sx={{padding: "1px 5px 1px 5px"}}>
                                                <ListItemButton style={{borderRadius: "10px"}} onClick={() => {
                                                    window.main.openLink("https://www.patreon.com/sanimeapp/membership");
                                                }}>
                                                    <ListItemIcon>
                                                        <VerifiedOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary="أزالة الأعلانات" />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </Box>
                                </Box>
                            </Dialog>
                            <Dialog
                                open={this.state.pin.open}
                                onClose={this.closePin}
                                aria-labelledby={this.state.pin.title}
                                aria-describedby={this.state.pin.message}
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {this.state.pin.title}
                                </DialogTitle>
                                <DialogContent>
                                    <Typography style={{textAlign: "center"}}>
                                        {this.state.pin.message}
                                    </Typography>
                                </DialogContent>
                                <DialogContent>
                                    <Typography color={"#03a9f4"} style={{textAlign: "center"}}>
                                        {this.state.pin.link}
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.closePin} autoFocus>
                                        حسنا
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Link id={'goRandom'} to={this.state.randomAnime} style={{display: 'none'}}/>
                        </main>
                    </ThemeProvider>
                </ColorModeContext.Provider>
            </BrowserRouter>
        );
    }
}

export default App;
