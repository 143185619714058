import React, {Component} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import {CircularProgress, List, Pagination} from "@mui/material";

export default class GenreView extends Component {

    componentDidMount() {}
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            error: false,
            newGenreListPage: 1,
            newGenreList: []
        };
        this.openGenreList(props.value);
    }
    animePageChanged = (event, value) => {
        this.setState({newGenreListPage: value});
    }
    checkTheme = () => {
        if (localStorage.getItem("theme") !== null) {
            return localStorage.getItem("theme").includes("true");
        } else {
            return true
        }
    }

    accountData = () => {
        if (this.checkLogged()) {
            return JSON.parse(localStorage.getItem("loggedData"));
        }
    }

    checkLogged = () => {
        return localStorage.getItem("logged") !== null;
    }

    openGenreList = (item) => {
        this.setState({
            isGenre: false,
            loading: true
        });

        let url = "https://app.sanime.net/function/h10.php?page=genre&id="+item.data;
        if (this.checkLogged()) {
            url = "https://app.sanime.net/function/h10.php?page=genre&id="+item.data+"&email="+this.accountData().email;
        }

        fetch(url).then((value) => {
            value.text().then((value) => {
                const list = JSON.parse(value);
                this.setState({
                    newGenre: true,
                    newGenreTitle: item.name,
                    loading: false,
                    newGenreList: list.array_chunk(30),
                    newGenreListPage: 1
                });
            });
        }).catch(() => {
            window.main.goLink("/");
        });
    }

    render() {
        if (this.state.loading) {
            return <Container style={{placeContent: 'center',textAlign: 'center',height: '100%'}}>
                <div style={{height: '350px'}} />
                <CircularProgress style={{color: '#00b2ff'}}/>
                <div/>
            </Container>
        } else {
            if (this.state.newGenreList.length === 0) {
                return <Box height={'100%'}>
                    <Box style={{marginBottom: '7px'}} height={'100%'}>
                        <List>
                            <ListItem style={{padding:"0px", placeContent: "center"}}>
                                <Typography style={{fontFamily: "'Changa'"}}>
                                    لاتوجد أنميات في هذا التصنيف
                                </Typography>
                            </ListItem>
                        </List>
                    </Box>
                </Box>;
            } else {
                return <Box height={'100%'}>
                    <Box style={{marginTop: '7px'}}>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" spacing={1.4}>
                                    {this.state.newGenreList[this.state.newGenreListPage - 1].map(r =>
                                        <Grid key={r.id} item>
                                            <Card sx={{ width: window.main.if360() ? 105 : 120 }} >
                                                <CardActionArea onClick={window.main.goLink.bind(this,'/info?id='+r.id)}>
                                                    <CardMedia
                                                        component="img"
                                                        height="185"
                                                        image={r.image}
                                                        alt={r.name}
                                                    />
                                                    <Divider sx={{height: 1.2}} color={'#00b2ff'}/>
                                                    <Stack style={{paddingRight: '4px',paddingLeft: '4px',paddingBottom: '3px',paddingTop: '1px'}} spacing={0}>
                                                        <Typography style={{textOverflow: "ellipsis",overflow: "hidden",whiteSpace: "nowrap",fontSize: '14px'}}>{r.name}</Typography>
                                                        <Typography style={{textAlign: 'right',fontSize: '14px'}}>{r.status}</Typography>
                                                    </Stack>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Pagination className={"centerPage"} style={{margin: '4px'}} siblingCount={0} size="large" color={"primary"} page={this.state.newGenreListPage} onChange={this.animePageChanged} count={this.state.newGenreList.length} variant="outlined" shape="rounded" />
                    </Box>
                </Box>;
            }
        }
    }
}