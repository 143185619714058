import '../App.css';
import React, {Component} from "react";
import {
    AppBar,
    Avatar,
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Divider, Fab,
    Grid,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MobileStepper,
    Paper,
    Stack,
    Toolbar,
    Typography, Zoom
} from "@mui/material";
import {CenterLoading, CustomTabPanel, Slider} from "../functions";
import {HomeIcon, ChatIcon, FavIcon, SettingIcon, SearchsIcon, LeftIcon, RightIcon, NewsIcon} from "../Icons";
import Grow from "@mui/material/Grow";
import Settings from "../fragment/settings";
import News from "../fragment/news";
import Favorite from "../fragment/favorite";
import {CloudDownloadOutlined, PostAddOutlined} from "@mui/icons-material";
import AnimeList from "./animelist";
import AnimeDate from "./animedate";
import NewAnime from "./newanime";
import GenreView from "./genre";
import CommunityPage from "../fragment/community";
import $ from "jquery";

class HomePage extends Component {
    componentDidMount() {
        this.prepare();
    }

    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            pageName: "الرئيسية",
            mode: "dark",
            loading: true,
            data: {},
            activeStep: 0,
            isOpen: false,
            insidePage: false,
            insideType: "animeList",
            genre: {
                name: "action",
                data: "genre0"
            },
            post:false
        };
        window.home = this;
    }
    handleNext = () => {
        this.setState({
            activeStep: this.state.activeStep + 1
        });
    };
    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1
        });
    };

    checkLogged = () => {
        return localStorage.getItem("logged") !== null;
    }

    accountData = () => {
        if (this.checkLogged()) {
            return JSON.parse(localStorage.getItem("loggedData"));
        }
    }

    prepare = () => {
        if (localStorage.getItem("latestAnime").length > 1) {
            let JS = JSON.parse(localStorage.getItem("latestAnime"));
            this.setState({
                data: JS,
                loading: false
            });
            window.main.setState({
                pin: JS["pin"]
            });
        } else {
            let url = 'https://app.sanime.net/function/h10.php?page=latest';
            if (this.checkLogged()) {
                url = 'https://app.sanime.net/function/h10.php?page=latest&email='+this.accountData().email;
            }
            fetch(url).then((value) => {
                value.text().then((value) => {
                    let JS = JSON.parse(value);
                    this.setState({
                        data: JS,
                        loading: false
                    });
                    window.main.setState({
                        pin: JS["pin"]
                    });
                    localStorage.setItem("latestAnime",value);
                });
            }).catch(() => {
                this.prepare();
            });
        }
    }
    goAccount = () => {
        window.favoritePage.getAccount();
    }
    close = () => {
        this.setState({
            insidePage: false,
        });
        window.scrollTo(0, 0);
    }
    openInside = (name) => {
        this.setState({
            insideType: name,
            insidePage: true
        });
    }
    render() {
        if (this.state.loading) {
            return ( <CenterLoading/> );
        } else {
            return (<Box>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static" elevation={0} color={"background"} enableColorOnDark>
                        <Toolbar variant="dense" sx={{paddingLeft: "9px",paddingRight:"9px"}}>
                            <ListItem sx={{padding: "0px"}}>
                                {
                                    this.state.value === 4 ? <Box/> : <Grow in={this.state.value !== 4}>
                                        <Box>
                                            <ListItemAvatar onClick={() => {
                                                this.setState({
                                                    value: 4,
                                                })
                                            }} sx={{minWidth: "unset",paddingRight: "8px"}}>
                                                <Avatar alt="Logo" src={window.main.checkLogged() ? window.main.accountData().image  : window.main.isOtakuTime() ? "https://anime-ar.com/icon_1024.png" : "https://sanime.net/app_icon.png"} />
                                            </ListItemAvatar>
                                        </Box>
                                    </Grow>
                                }
                                <ListItemText primary={window.main.isOtakuTime() ? "OtakuTime" : "SAnime"} secondary={this.state.pageName} />
                                <IconButton onClick={() => {
                                    window.main.setState({
                                       searchDialog: true
                                    });
                                    setTimeout(() => {
                                        document.getElementById("searchId").addEventListener("keypress", function(event) {
                                            if (event.key === "Enter") {
                                                event.preventDefault();
                                                window.main.goSearch();
                                            }
                                        });
                                    },600);
                                }} aria-label="comment">
                                    <SearchsIcon />
                                </IconButton>
                                {
                                    this.state.value === 2 ? <IconButton color="primary" aria-label="sync account" onClick={this.goAccount}>
                                        <CloudDownloadOutlined />
                                    </IconButton> : <Box/>
                                }
                                {
                                    this.state.post ? <IconButton color="primary" aria-label="sync account" onClick={() => {
                                        window.community.openAdd();
                                    }}>
                                        <PostAddOutlined />
                                    </IconButton> : <Box/>
                                }
                            </ListItem>
                        </Toolbar>
                    </AppBar>
                </Box>
                <Box sx={{ width: '100%', padding: "0px" }}>
                    <CustomTabPanel value={this.state.value} index={0} style={{padding: "0px"}}>
                        {
                            this.state.insidePage ? <Box/> : <Grow in={this.state.value === 0} timeout={200}>
                                <div>
                                    <Box className={"titleHeader"}>
                                        <Button
                                            size="small"
                                            onClick={this.openInside.bind(this,"animeNew")}
                                        >
                                            <LeftIcon />
                                            <span style={{fontFamily: "Changa",fontSize: "12px"}}>عرض الكل</span>
                                        </Button>
                                        <Button size="medium" sx={{color: "primary.main"}} className={"unsetBTN"}>
                                            <span style={{fontFamily: "Changa",fontSize: "12px"}}>الأنميات الجديدة</span>
                                        </Button>
                                    </Box>
                                    <Box>
                                        {this.state.data["anime"].slice(0,10).map((item,index) => (
                                            <Slider key={item.id} value={this.state.activeStep} index={index}>
                                                <Card onClick={() => {
                                                    window.main.goLink("/info?id="+item.id);
                                                }} style={{borderRadius:"8px",width:"100%",height: "200px",backgroundAttachment: "fixed",background: "url("+item.image+") center center"}} className={"iphoneBack"}>
                                                    <Box style={{width:"100%",height: "100%", backgroundColor: window.main.state.mode.includes("dark") ? "rgba(0,0,0,0.72)" : "rgba(65,62,62,0.64)"}}>
                                                        <Card sx={{ display: 'flex',height: "100%",padding: "7px",background: "transparent" }}>
                                                            <CardMedia
                                                                component="img"
                                                                sx={{ width: 120,padding: "1px",borderRadius: "8px"}}
                                                                image={item.image}
                                                                alt="Live from space album cover"
                                                            />
                                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                <CardContent sx={{ flex: '1 0 auto',padding: "5px"}}>
                                                                <span style={{fontFamily: "Righteous",width:"100%",color: "white"}} className={"oneLine"}>
                                                                    {
                                                                        item.name
                                                                    }
                                                                </span>
                                                                    <Typography variant="subtitle1" color="white" component="div" style={{fontFamily: "Changa", fontSize:"12px"}} className={"oneLine"}>
                                                                        {
                                                                            item.genre
                                                                        }
                                                                    </Typography>
                                                                    <Typography variant="subtitle1" color="white" component="div" style={{fontWeight: "bold",fontFamily: "Changa", fontSize:"12px",textOverflow:"ellipsis",whiteSpace: "nowrap"}}>
                                                                        قصة الأنمي
                                                                    </Typography>
                                                                    <Typography variant="subtitle1" color="white" component="div" style={{fontFamily: "Changa", fontSize:"12px"}} className={"fourLine"}>
                                                                        {
                                                                            item.story
                                                                        }
                                                                    </Typography>
                                                                </CardContent>
                                                            </Box>

                                                        </Card>
                                                    </Box>
                                                </Card>
                                            </Slider>
                                        ))}
                                    </Box>
                                    <MobileStepper
                                        steps={this.state.data["anime"].slice(0,10).length}
                                        position="static"
                                        activeStep={this.state.activeStep}
                                        nextButton={
                                            <Button
                                                size="small"
                                                onClick={this.handleNext}
                                                disabled={this.state.activeStep === this.state.data["anime"].slice(0,10).length - 1}
                                            >
                                                <span>التالي</span>
                                                <RightIcon />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={this.handleBack} disabled={this.state.activeStep === 0}>
                                                <LeftIcon />
                                                <span>السابق</span>
                                            </Button>
                                        }
                                    />
                                    <Box className={"titleHeader"}>
                                        <Button size="medium" disabled={true} style={{color: "white"}}>
                                            <span/>
                                        </Button>
                                        <div>
                                            <span style={{fontFamily: "Changa",fontSize: "12px"}}>الأقسام الاساسية</span>
                                        </div>
                                    </Box>
                                    <div className="scrollmenu" style={{backgroundColor: '#00000000'}}>
                                        <div>
                                            <Button onClick={this.openInside.bind(this,"animeList")} style={{margin: "0px 3px 0px 3px",padding: "0px",backgroundColor:"#00000000",border:"solid",borderWidth: "thin",borderRadius: "6px"}} >
                                                <Typography style={{fontFamily: 'Changa',fontSize: "12px",padding: '5px 10px 5px 10px'}}>قائمة الأنمي</Typography>
                                            </Button>
                                        </div>
                                        <div>
                                            <Button onClick={() => {
                                                const randomItem = this.state.data["anime"][Math.floor(Math.random() * this.state.data["anime"].length)];
                                                window.main.goLink("/info?id="+randomItem.id);
                                            }} style={{margin: "0px 3px 0px 3px",padding: "0px",backgroundColor:"#00000000",border:"solid",borderWidth: "thin",borderRadius: "6px"}} >
                                                <Typography style={{fontFamily: 'Changa',fontSize: "12px",padding: '5px 10px 5px 10px'}}>أنمي عشوائي</Typography>
                                            </Button>
                                        </div>
                                        <div>
                                            <Button onClick={this.openInside.bind(this,"animeDate")} style={{margin: "0px 3px 0px 3px",padding: "0px",backgroundColor:"#00000000",border:"solid",borderWidth: "thin",borderRadius: "6px"}} >
                                                <Typography style={{fontFamily: 'Changa',fontSize: "12px",padding: '5px 10px 5px 10px'}}>مواعيد الأنمي</Typography>
                                            </Button>
                                        </div>
                                        <div>
                                            <Button onClick={() => {
                                                this.setState({
                                                    value: 3
                                                });
                                            }} style={{margin: "0px 3px 0px 3px",padding: "0px",backgroundColor:"#00000000",border:"solid",borderWidth: "thin",borderRadius: "6px"}} >
                                                <Typography style={{fontFamily: 'Changa',fontSize: "12px",padding: '5px 10px 5px 10px'}}>الأخبار</Typography>
                                            </Button>
                                        </div>
                                        {
                                            window.main.isOtakuTime() ? <div style={{display: "none"}}/> : <div>
                                                <Button onClick={() => {
                                                    this.setState({
                                                        value: 1
                                                    });
                                                }} style={{margin: "0px 3px 0px 3px",padding: "0px",backgroundColor:"#00000000",border:"solid",borderWidth: "thin",borderRadius: "6px"}} >
                                                    <Typography style={{fontFamily: 'Changa',fontSize: "12px",padding: '5px 10px 5px 10px'}}>المجتمع</Typography>
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                    <Box className={"titleHeader"}>
                                        <Button size="medium" disabled={true} style={{color: "white"}}>
                                            <span/>
                                        </Button>
                                        <div>
                                            <span style={{fontFamily: "Changa",fontSize: "12px"}}>التصنيفات</span>
                                        </div>
                                    </Box>
                                    <div className="scrollmenu" style={{backgroundColor: '#00000000'}}>
                                        {this.state.data["genre"].map((step) => (
                                            <div key={step.name}>
                                                <Button onClick={() => {
                                                    this.setState({
                                                        genre: step
                                                    });
                                                    this.openInside("genrePage");
                                                }} style={{margin: "0px 3px 0px 3px",padding: "0px",backgroundColor:"#00000000",border:"solid",borderWidth: "thin",borderRadius: "6px"}} >
                                                    <Typography style={{fontFamily: 'Changa',fontSize: "12px",padding: '5px 10px 5px 10px'}}>{step.name}</Typography>
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                    <Box className={"titleHeader"}>
                                        <Button size="medium" disabled={true} style={{color: "white"}}>
                                            <span/>
                                        </Button>
                                        <div>
                                            <span style={{fontFamily: "Changa",fontSize: "12px"}}>الحلقات الجديدة</span>
                                        </div>
                                    </Box>
                                    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent="center" spacing={1.4}>
                                                {this.state.data["latest"].slice(0,30).map(r =>
                                                    <Grid key={r.id} item>
                                                        <Card sx={{ width: window.main.if360() ? 105 : 120 }} >
                                                            <CardActionArea onClick={window.main.goLink.bind(this,'/info?id='+r.id)}>
                                                                <CardMedia
                                                                    component="img"
                                                                    height="185"
                                                                    image={r.image}
                                                                    alt={r.name}
                                                                />
                                                                <Divider sx={{height: 1.2}} color={'#00b2ff'}/>
                                                                <Stack style={{paddingRight: '4px',paddingLeft: '4px',paddingBottom: '3px',paddingTop: '1px'}} spacing={0}>
                                                                    <Typography style={{textOverflow: "ellipsis",overflow: "hidden",whiteSpace: "nowrap",fontSize: '12px'}}>{r.name}</Typography>
                                                                    <Typography style={{textAlign: 'right',fontSize: '12px',fontFamily:"Changa"}}>{r.epName}</Typography>
                                                                </Stack>
                                                            </CardActionArea>
                                                        </Card>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grow>
                        }
                        {
                            this.state.insidePage ? <Grow in={this.state.insidePage} timeout={200}>
                                <div>
                                    {
                                        this.state.insideType.includes("animeList") ? <AnimeList/> :
                                        this.state.insideType.includes("animeDate") ? <AnimeDate/> :
                                        this.state.insideType.includes("animeNew") ? <NewAnime/> :
                                        this.state.insideType.includes("genrePage") ? <GenreView value={this.state.genre}/> : <NewAnime/>
                                    }
                                </div>
                            </Grow> : <Box/>
                        }

                    </CustomTabPanel>
                    <CustomTabPanel value={this.state.value} index={1} zeropadding={"true"}>
                        <Grow in={this.state.value === 1}>
                            <Box margin={0} padding={0}>
                                <CommunityPage/>
                            </Box>
                        </Grow>
                    </CustomTabPanel>
                    <CustomTabPanel value={this.state.value} index={2} zeropadding={"true"}>
                        <Grow in={this.state.value === 2}>
                            <Box margin={0} padding={0}>
                                <Favorite/>
                            </Box>
                        </Grow>
                    </CustomTabPanel>
                    <CustomTabPanel value={this.state.value} index={3}>
                        <Grow in={this.state.value === 3}>
                                    <Box style={{height: "calc(85vh)"}}>
                                        <News/>
                                    </Box>
                        </Grow>
                    </CustomTabPanel>
                    <CustomTabPanel value={this.state.value} index={4}>
                        <Grow in={this.state.value === 4}>
                                    <Box>
                                        <Settings/>
                                    </Box>
                        </Grow>
                    </CustomTabPanel>
                </Box>
                <Zoom
                    in={this.state.insidePage}
                    timeout={300}
                    unmountOnExit
                >
                    <Fab onClick={this.close} className={"fabMenu"} variant="extended" sx={{fontFamily: "Changa",backgroundColor: "primary.cardTwo",color: "primary.card"}} aria-label={"add comment"}>
                        {
                            "رجوع"
                        }
                    </Fab>
                </Zoom>
                {
                    window.main.ios() ? <Box sx={{width: '100%', paddingBottom: "122px"}}/> : <Box sx={{width: '100%', paddingBottom: "55px"}}/>
                }
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, borderTopLeftRadius: 8, borderTopRightRadius: 8}} elevation={0}>
                    <BottomNavigation
                        sx={{borderTopLeftRadius: 8, borderTopRightRadius: 8,marginBottom: window.main.ios() ? "14px" : "0px"}}
                        value={this.state.value}
                        onChange={(event, newValue) => {
                            if (newValue === 1) {
                                if (window.main.isOtakuTime()) {
                                    let item = JSON.parse("{}");
                                    item.type = "toMain";
                                    window.webkit.messageHandlers.m.postMessage(item)
                                } else {
                                    this.setState({
                                        value: newValue,
                                    });
                                }
                            } else {
                                this.setState({
                                    value: newValue,
                                });
                            }
                            switch (newValue) {
                                case 0:
                                    this.setState({
                                        pageName: "الرئيسية",
                                        post: false
                                    });
                                    break;
                                case 1:
                                    if (!window.main.isOtakuTime()) {
                                        this.setState({
                                            pageName: "المجتمع"
                                        });
                                    }
                                    break;
                                case 2:
                                    this.setState({
                                        pageName: "المفضلة",
                                        post: false
                                    });
                                    break;
                                case 3:
                                    this.setState({
                                        pageName: "الأخبار",
                                        post: false
                                    });
                                    break;
                                case 4:
                                    this.setState({
                                        pageName: "الأعدادات",
                                        post: false
                                    });
                                    break;
                                default:
                                    this.setState({
                                        pageName: "الرئيسية",
                                        post: false
                                    });
                            }
                            const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
                            for (let i = 1; i < interval_id; i++) {
                                window.clearInterval(i);
                            }
                            setTimeout(() => {
                                $("main").animate({ scrollTop: 0 }, 500);
                            },200);
                        }}
                    >
                        <BottomNavigationAction sx={{minWidth: "60px"}} label="الرئيسية" icon={<HomeIcon />} />
                        <BottomNavigationAction sx={{minWidth: "60px"}} label="المجتمع" icon={<ChatIcon />} />
                        <BottomNavigationAction sx={{minWidth: "60px"}} label="المفضلة" icon={<FavIcon />} />
                        <BottomNavigationAction sx={{minWidth: "60px"}} label="الأخبار" icon={<NewsIcon />} />
                        <BottomNavigationAction sx={{minWidth: "60px"}} label="الأعدادات" icon={<SettingIcon />} />
                    </BottomNavigation>
                </Paper>
            </Box>);
        }
    }
}
export default HomePage;
