import React, {Component} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {decodeTool} from "../functions";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {NewReleasesSharp} from "@mui/icons-material";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {CircularProgress, Fab, Zoom} from "@mui/material";

export default class News extends Component {

    componentDidMount() {}

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            error: false,
            isOpen: true,
            newsTitle: "",
            url: ""
        };
        this.prepare();
    }
    checkTheme = () => {
        if (localStorage.getItem("theme") !== null) {
            return localStorage.getItem("theme").includes("true");
        } else {
            return true
        }
    }

    prepare = () => {
            fetch("https://app.sanime.net/function/h10.php?page=news").then((value) => {
                value.text().then((value) => {
                    const list = JSON.parse(value);
                    this.setState({
                        data: list,
                        isOpen: false,
                        loading: false
                    });
                });
            }).catch((error) => {
                this.setState({
                    loading: true,
                    error: true
                });
            });
    }
    restartPage = () => {
        this.setState({
            error: false,
            loading: true
        });
        this.prepare();
    }
    close = () => {
        this.setState({
            isOpen: false,
        });
    }
    open = (url) => {
        this.setState({
            url: "about:blank",
        });
        if (this.checkTheme()) {
            this.setState({
                url: url.id + '&style=dark',
                newsTitle: url.title,
                isOpen: true,
            });
        } else {
            this.setState({
                url: url.id + '&style=light',
                newsTitle: url.title,
                isOpen: true,
            });
        }
    }
    render() {
        if (this.state.error) {
            return <Box>
                <Container style={{placeContent: 'center',textAlign: 'center'}}>
                    <Card>
                        <NewReleasesSharp style={{margin: "10px"}}/>
                        <CardContent style={this.checkTheme() ? {color: '#ffffff'} : {color: '#000000'}}>
                            توجد مشكلة في الشبكة حاول مجددا
                        </CardContent>
                        <Button onClick={this.restartPage} style={{margin: "8px", backgroundColor: '#03a9f4',color: 'white'}}>
                            أعادة المحاولة
                        </Button>
                    </Card>
                </Container>
            </Box>
        } else {
            if (this.state.loading) {
                return <Container style={{placeContent: 'center',textAlign: 'center',height: '100%'}}>
                    <div style={{height: '350px'}} />
                    <CircularProgress style={{color: '#00b2ff'}}/>
                    <div/>
                </Container>
            } else {
                return <Box style={{height: "calc(85vh)"}}>
                    {
                        this.state.isOpen ? <Box height={'100%'}>
                            <div className={'scroll-wrapper iframe-loading'} style={{height: '100%'}}>
                                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                                <iframe className={'scroll-wrapper'} src={this.state.url} frameBorder="0" width={'100%'} height={'99%'}/>
                            </div>
                        </Box>: <Box height={'100%'}>
                            <Box style={{marginTop: '7px'}}>
                                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="center" spacing={1.4}>
                                            {this.state.data.map(r =>
                                                <Grid key={r.id} item>
                                                    <Card sx={{ width: window.main.if360() ? 105 : 120 }} onClick={this.open.bind(this,r)}>
                                                        <CardActionArea>
                                                            <CardMedia
                                                                component="img"
                                                                height="185"
                                                                image={r.image}
                                                                alt={r.title}
                                                            />
                                                            <Divider sx={{height: 1.2}} color={'#00b2ff'}/>
                                                            <Stack style={{paddingRight: '4px',paddingLeft: '4px',paddingBottom: '3px',paddingTop: '1px'}} spacing={0}>
                                                                <Typography style={{fontSize: '14px'}} className={"oneLine"}>{r.title}</Typography>
                                                                <Typography style={{textAlign: 'right',fontSize: '14px'}}>{r.time}</Typography>
                                                            </Stack>
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Box sx={{height: "135px"}}/>
                            </Box>
                        </Box>
                    }
                    <Zoom
                        in={this.state.isOpen}
                        timeout={300}
                        unmountOnExit
                    >
                        <Fab onClick={this.close} className={"fabNews"} variant="extended" sx={{fontFamily: "Changa",backgroundColor: "primary.cardTwo",color: "primary.card"}} aria-label={"add comment"}>
                            {
                                "رجوع"
                            }
                        </Fab>
                    </Zoom>
                </Box>
            }
        }
    }
}