import React from 'react';
import {Box, CircularProgress, Container, PaletteMode, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import PropTypes from "prop-types";
import styled from "styled-components";
import Grow from '@mui/material/Grow';

export const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                // palette values for light mode
                primary: {
                    main:'#000000',
                    card: '#FFFFFF',
                    cardTwo: '#262e39'
                },
                divider: grey[500],
                background: {
                    default: '#efeeee',
                    paper: '#efeeee',
                },
                text: {
                    sec: 'rgba(0,0,0,0.35)',
                    primary: '#000',
                    secondary: grey[500],
                },
            }
            : {
                // palette values for dark mode
                primary: {
                    main:'#ffffff',
                    card: '#262e39',
                    cardTwo: '#ffffff'
                },
                divider: grey[500],
                background: {
                    default: '#182128',
                    paper: '#182128',
                },
                text: {
                    sec: 'rgba(255,255,255,0.35)',
                    primary: '#fff',
                    secondary: grey[500],
                },
            }),
    },
});

export const ColorModeContext = React.createContext({
    toggleColorMode: () => {
        // This is intentional
    },
});

export function CenterLoading() {
    return <Box style={{height: '100%',width: "100%", position: "absolute"}}>
        <Container style={{placeContent: 'center',textAlign: 'center',height: '100%'}}>
            {window.main.isOtakuTime() ? <div style={{height: '45%'}} /> : <div style={{display: "none"}}/>}
            <CircularProgress style={{color: '#00b2ff'}}/>
            <div/>
        </Container>
    </Box>
}

export function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    let zero = "2px 8px 8px 8px";
    if (props.zeropadding !== undefined) {
        if (props.zeropadding.includes("true")) {
            zero = "0px 0px 0px 0px";
        } else {
            zero = "2px 8px 8px 8px";
        }
    }
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{padding: zero }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    zeropadding: PropTypes.string
};


export function decodeTool(res) {
    var hex  = atob(res);
    var str = '';
    for (var n = 0; n < hex.length; n += 2) {
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
}

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: green;
  width: 100%;
  height: 200px;
  margin: 1px;
`;

export function Slider(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            hidden={value !== index}
            id={`slider-item-${index}`}
            aria-labelledby={`slider-item-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Grow in={value === index}>{children}</Grow>
                </Box>
            )}
        </div>
    );
}

export const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: window.main.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    top: 8,
    left: 'calc(50% - 15px)',
}));

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export function CenterEmpty() {
    return <Box style={{height: '100%'}}>
        <Container style={{placeContent: 'center',textAlign: 'center',height: '100%'}}>
            <div style={{height: '45%'}} />
            <Typography style={{marginTop: "4px"}}>
                الصفحة خالية
            </Typography>
            <div/>
        </Container>
    </Box>
}