import React, {Component} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {CircularProgress, Container, List, ListItem, ListItemButton, ListItemText, Pagination} from "@mui/material";
import {BottomSheet} from "../../../src/bottom_sheet_mui/src/components/BottomSheet";
import * as PropTypes from "prop-types";
import * as Icons from '@mui/icons-material';
import ListItemIcon from "@mui/material/ListItemIcon";


ListItemIcon.propTypes = {children: PropTypes.node};
export default class Watched extends Component {

    componentDidMount() {}

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            page: 1,
            data: [],
            error: false,
            menuFav: false,
            menuID: '',
            menuWatchData: {},
        };
        this.prepare();
    }
    checkTheme = () => {
        if (localStorage.getItem("theme") !== null) {
            return localStorage.getItem("theme").includes("true");
        } else {
            return true
        }
    }
    prepare = () => {
        setTimeout(() => {
            let list = this.getAllWatch();
            this.setState({
                data: list.array_chunk(30),
                page: 1,
                loading: false,
                menuFav: false
            });
        },100);
    }
    showMenu = (item) => {
        this.setState({
            menuFav: true,
            menuID: item.id,
            menuWatchData: item
        });
    }
    restartPage = () => {
        this.setState({
            loading: true,
            error: false
        });
        setTimeout(()=> {
            this.prepare();
        },500);
    }
    animePageChanged = (event, value) => {
        this.setState({page: value});
    }
    closeMenuFav = () => {
        this.setState({menuFav: false});
    }
    showAnime = () => {
        window.main.goLink("/info?id="+this.state.menuWatchData.id);
    }
    menuDeleteAll = () => {
        localStorage.removeItem("WATCH_"+this.state.menuWatchData.id);
        let list = this.getAllWatch();
        this.setState({
            data: list.array_chunk(30),
            page: 1,
            loading: false,
            menuFav: false
        });
    }
    menuDeleteLast = () => {
        this.state.menuWatchData.ep.pop();
        localStorage.removeItem("WATCH_"+this.state.menuWatchData.id);
        if (this.state.menuWatchData.ep.length === 0) {
            let list = this.getAllWatch();
            this.setState({
                data: list.array_chunk(30),
                page: 1,
                loading: false,
                menuFav: false
            });
        } else {
            localStorage.setItem("WATCH_"+this.state.menuWatchData.id,JSON.stringify(this.state.menuWatchData));
            let list = this.getAllWatch();
            this.setState({
                data: list.array_chunk(30),
                page: 1,
                loading: false,
                menuFav: false
            });
        }
    }
    getAllWatch = () => {
        let list = [], keys = Object.keys(localStorage), i = keys.length;
        while ( i-- ) {
            if (keys[i].includes("WATCH_")) {
                list.push(JSON.parse(localStorage.getItem(keys[i])));
            }
        }
        return list;
    }
    render() {
        if (this.state.loading) {
            return <Container style={{placeContent: 'center',textAlign: 'center',height: '100%'}}>
                <div style={{height: '350px'}} />
                <CircularProgress style={{color: '#00b2ff'}}/>
                <div/>
            </Container>
        } else {
            return <Box>
                {this.state.data.length === 0 ? <Box style={{marginBottom: '7px'}} height={'100%'}>
                    <List>
                        <ListItem style={{padding:"0px", placeContent: "center"}}>
                            <Typography style={{fontFamily: "'Changa'"}}>
                                قائمة المشاهدات خالية
                            </Typography>
                        </ListItem>
                    </List>
                </Box> : <Box style={{marginTop: '7px',marginBottom: '7px'}} height={'100%'}>
                    <Grid style={{paddingBottom: '6px'}} sx={{ flexGrow: 1 }} container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center" spacing={1.4}>
                                {this.state.data[this.state.page - 1].map(r =>
                                    <Grid key={r.id} item>
                                        <Card sx={{ width: window.main.if360() ? 105 : 120 }} onClick={this.showMenu.bind(this,r)} >
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="185"
                                                    image={r.image}
                                                    alt={r.title}
                                                />
                                                <Divider sx={{height: 1.2}} color={'#00b2ff'}/>
                                                <Stack style={{paddingRight: '4px',paddingLeft: '4px',paddingBottom: '3px',paddingTop: '1px'}} spacing={0}>
                                                    <Typography style={{textOverflow: "ellipsis",overflow: "hidden",whiteSpace: "nowrap",fontSize: '14px'}}>{r.title}</Typography>
                                                    <Typography style={{textAlign: 'right',fontSize: '14px'}}>{r.ep[r.ep.length-1].name}</Typography>
                                                </Stack>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        this.state.data.length === 1 ? <Box/> :  this.state.data.length === 0 ? <Box/> : <Pagination className={"centerPage"} style={{margin: '4px'}} siblingCount={0} size="large" color={"primary"} page={this.state.page} onChange={this.animePageChanged} count={this.state.data.length} variant="outlined" shape="rounded" />
                    }
                    <BottomSheet contentStyle={this.checkTheme() ? {backgroundColor: "#252e39"} : {backgroundColor: "white"}} onRequestClose={() => this.closeMenuFav()} open={this.state.menuFav}>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton onClick={this.showAnime}>
                                    <ListItemIcon>
                                        <Icons.InfoOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="صفحة الأنمي" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton onClick={this.menuDeleteLast}>
                                    <ListItemIcon>
                                        <Icons.Delete />
                                    </ListItemIcon>
                                    <ListItemText primary="حذف أخر حلقة" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton onClick={this.menuDeleteAll}>
                                    <ListItemIcon>
                                        <Icons.DeleteForeverOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="حذف كل الحلقات" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </BottomSheet>
                </Box>}
            </Box>
        }
    }
}