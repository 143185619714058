import React, {Component} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {CenterLoading} from "../functions";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import {ArrowBack, Visibility, VisibilityOff} from "@mui/icons-material";
import {
    AppBar,
    CircularProgress, Dialog,
    FormControl,
    IconButton, ImageList, ImageListItem,
    InputAdornment,
    InputLabel,
    ListItem,
    OutlinedInput, Toolbar
} from "@mui/material";
import $ from "jquery";

export default class Update extends Component {

    componentDidMount() {}

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        setTimeout(() => {
            if (localStorage.getItem("logged") !== null) {} else {
                window.main.goLink("/account");
            }
        },100);
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            error: false,
            loginPage: false,
            loginData: {
                email: "",
                password: "",
                username: "",
                bio: "",
                image: ""
            },
            loginTitle: 'تعديل الحساب',
            loginIsRegister: false,
            showPassword: false,
            loginLoading: false,
            imageListOpen: false,
            imageList: []
        };
        this.prepare();
    }

    closeImageList = () => {
        this.setState({
            imageListOpen: false
        })
    }
    openImageList = () => {
        window.main.switchLoading();
        fetch('https://app.sanime.net/function/h10.php?page=images').then((value) => {
            value.text().then((value) => {
                const json = JSON.parse(value);
                this.setState({
                    imageListOpen: true,
                    imageList: json
                });
            }).catch((err) => {
                this.setState({
                    loading: false,
                });
            });window.main.switchLoading();
        }).catch((err) => {
            window.main.switchLoading();
            window.main.showMessage("توجد مشكلة في الشبكة","تنبيه","حسنا","",true);
        });
    }

    checkTheme = () => {
        if (localStorage.getItem("theme") !== null) {
            return localStorage.getItem("theme").includes("true");
        } else {
            return true
        }
    }

    prepare = () => {
        setTimeout(() => {
            if (localStorage.getItem("logged") !== null) {
                if (localStorage.getItem("logged") !== null) {
                    let data = JSON.parse(localStorage.getItem("loggedData"));
                    this.setState({
                        loginData: data,
                    })
                }
            } else {
                window.main.goLink("/account");
            }
        },100);
    }

    restartPage = () => {
        this.setState({
            loading: true,
            error: false
        });
        setTimeout(()=> {
            this.prepare();
        },500);
    }

    setImage = (value) => {
        this.setState({
            loginData: {
                email: this.state.loginData.email,
                password: this.state.loginData.password,
                username: this.state.loginData.username,
                bio: this.state.loginData.bio,
                image: value
            },
        });
        this.closeImageList();
        window.main.showMessage("تم أختيار الصورة","تنبيه","حسنا","",true);
    };

    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword})
    };
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    onPassword = (value) => {
        this.setState({
            loginData: {
                email: this.state.loginData.email,
                password: value.target.value,
                username: this.state.loginData.username,
                bio: this.state.loginData.bio,
                image: this.state.loginData.image
            },
        });
    };
    onBio = (value) => {
        this.setState({
            loginData: {
                email: this.state.loginData.email,
                password: this.state.loginData.password,
                username: this.state.loginData.username,
                bio: value.target.value,
                image: this.state.loginData.image
            },
        });
    };
    onImage = (value) => {
        this.setState({
            loginData: {
                email: this.state.loginData.email,
                password: this.state.loginData.password,
                username: this.state.loginData.username,
                bio: this.state.loginData.bio,
                image: value.target.value
            },
        });
    };
    onUsername = (value) => {
        if (value.target.value.length < 20) {
            this.setState({
                loginData: {
                    email: this.state.loginData.email,
                    password: this.state.loginData.password,
                    username: value.target.value,
                    bio: this.state.loginData.bio,
                    image: this.state.loginData.image
                },
            });
        } else {
            window.main.showMessage("يجب أن يكون أسم المستخدم أقل من 20 حرف","تنبيه","حسنا","",true);
        }
    };
    onEmail = (value) => {
        this.setState({
            loginData: {
                email: value.target.value,
                password: this.state.loginData.password,
                username: this.state.loginData.username,
                bio: this.state.loginData.bio,
                image: this.state.loginData.image
            },
        });
    };

    goLogin = () => {
        window.main.switchLoading();
        if (localStorage.getItem("logged") !== null) {
            let data = JSON.parse(localStorage.getItem("loggedData"));
            fetch('https://app.sanime.net/function/h10.php?page=update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: 'e=' + data.email + '&p=' + data.password + '&base=' + btoa(unescape(encodeURIComponent(JSON.stringify(this.state.loginData))))
            }).then((value) => {
                value.text().then((value) => {
                    const json = JSON.parse(value);
                    if (json.code === "1") {
                        window.main.switchLoading();
                        let userData = JSON.parse(localStorage.getItem('loggedData'));
                        userData.password = this.state.loginData.password;
                        localStorage.setItem('loggedData', JSON.stringify(userData));
                        if (window.main.isOtakuTime()) {
                            window.main.otakuLogin(this.state.loginData.email,this.state.loginData.password);
                            setTimeout(function () {
                                window.main.otakuRestart();
                            },2000);
                        } else {
                            window.main.reLogin2(this.state.loginData.email,this.state.loginData.password);
                        }
                    } else {
                        window.main.showMessage(json.message,"تنبيه","حسنا","",true);
                        window.main.switchLoading();
                    }
                }).catch((err) => {
                    window.main.switchLoading();
                });
            }).catch((err) => {
                window.main.switchLoading();
            });
        }
    }

    render() {
        if (this.state.loading) {
            return <CenterLoading/>
        } else {
            return <Box style={{marginTop: '0px',marginBottom: '7px'}} height={'100%'}>
                <AppBar position="static" color="background" enableColorOnDark elevation={0}>
                    <Toolbar>
                        <IconButton
                            onClick={window.main.goLink.bind(this,"/")}
                            size="medium"
                            edge="start"
                            color="inherit"
                            aria-label="back"
                            sx={{ marginRight: "0px" }}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1,textAlign: "center",fontSize: "17px",fontFamily: "Righteous",paddingRight: "9px" }} className={"oneLine"}>
                            {this.state.loginTitle}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Card style={{margin: "13px",backgroundColor:"transparent"}} elevation={0}>
                    <List>
                        <ListItem style={{padding:"0px"}}>
                            <FormControl fullWidth focused color={"primary"} style={{margin: "5px"}} variant="outlined">
                                <InputLabel style={this.checkTheme() ? {color: 'white'} : {color: 'black'}} htmlFor="outlined-adornment-password">أسم المستخدم</InputLabel>
                                <OutlinedInput
                                    style={this.checkTheme() ? {color: 'white'} : {color: 'black'}}
                                    id="uername"
                                    type={'text'}
                                    value={this.state.loginData.username}
                                    onChange={this.onUsername}
                                    onClick={() => {
                                        setTimeout(() => {
                                            $("html").animate({ scrollTop: -10000 }, 500);
                                            $("body").animate({ scrollTop: -10000 }, 500);
                                        },200);
                                    }}
                                    label="أسم المستخدم"
                                />
                            </FormControl>
                        </ListItem>
                        <ListItem style={{padding:"0px"}}>
                            <FormControl fullWidth focused color={"primary"} style={{margin: "5px"}} variant="outlined">
                                <InputLabel style={this.checkTheme() ? {color: 'white'} : {color: 'black'}} htmlFor="outlined-adornment-password">كلمة المرور</InputLabel>
                                <OutlinedInput
                                    style={this.checkTheme() ? {color: 'white'} : {color: 'black'}}
                                    id="passwords"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    value={this.state.loginData.password}
                                    onChange={this.onPassword}
                                    onClick={() => {
                                        setTimeout(() => {
                                            $("html").animate({ scrollTop: -10000 }, 500);
                                            $("body").animate({ scrollTop: -10000 }, 500);
                                        },200);
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="كلمة المرور"
                                />
                            </FormControl>
                        </ListItem>
                        <ListItem style={{padding:"0px", placeContent: "center"}}>
                            <ListItem onClick={this.openImageList} button style={{width: '40%',margin: "7px", borderRadius: "5px",backgroundColor: "#2196f3", textAlign: "center",placeContent: "center"}}>
                                <Typography style={{fontFamily: "'Changa'",color: 'white'}}>
                                    {'أختيار صورة'}
                                </Typography>
                            </ListItem>
                        </ListItem>
                        <ListItem style={{padding:"0px"}}>
                            <FormControl fullWidth focused color={"primary"} style={{margin: "5px"}} variant="outlined">
                                <InputLabel style={this.checkTheme() ? {color: 'white'} : {color: 'black'}} htmlFor="outlined-adornment-password">رابط الصورة الشخصية</InputLabel>
                                <OutlinedInput
                                    style={this.checkTheme() ? {color: 'white'} : {color: 'black'}}
                                    id="uername"
                                    type={'text'}
                                    value={this.state.loginData.image}
                                    onChange={this.onImage}
                                    onClick={() => {
                                        setTimeout(() => {
                                            $("html").animate({ scrollTop: -10000 }, 500);
                                            $("body").animate({ scrollTop: -10000 }, 500);
                                        },200);
                                    }}
                                    label="رابط الصورة الشخصية"
                                />
                            </FormControl>
                        </ListItem>
                        {this.state.loginLoading ? <Container style={{margin: '5px',placeContent: 'center',textAlign: 'center'}}>
                            <CircularProgress style={{color: '#00b2ff'}}/>
                        </Container> : <ListItem style={{padding:"0px", placeContent: "center"}}>
                            <ListItem onClick={this.goLogin} button style={{width: '40%',margin: "7px", borderRadius: "5px",backgroundColor: "#2196f3", textAlign: "center",placeContent: "center"}}>
                                <Typography style={{fontFamily: "'Changa'",color: 'white'}}>
                                    {'تحديث المعلومات'}
                                </Typography>
                            </ListItem>
                        </ListItem>}
                    </List>
                </Card>
                <Dialog onClose={this.closeImageList} open={this.state.imageListOpen}>
                    <AppBar position="static" color="background" enableColorOnDark elevation={0}>
                        <Toolbar>
                            <IconButton
                                onClick={this.closeImageList}
                                size="medium"
                                edge="start"
                                color="inherit"
                                aria-label="back"
                                sx={{ marginRight: "0px" }}
                            >
                                <ArrowBack />
                            </IconButton>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1,textAlign: "center",fontSize: "17px",fontFamily: "Righteous",paddingRight: "9px" }} className={"oneLine"}>
                                أختيار صورة
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <ImageList sx={{margin: "0px" }} cols={3} rowHeight={164}>
                        {this.state.imageList.map((item) => (
                            <ImageListItem key={item.image} onClick={this.setImage.bind(this,item.image)}>
                                <img
                                    src={`${item.image}`}
                                    alt={item.image}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Dialog>
            </Box>
        }
    }
}